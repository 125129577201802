import { mapGetters } from 'vuex';

import { getIpInfo } from '@/api/ipdata';
import Cookies from 'js-cookie';
import productViewMixin from './productViewMixin';
import createOrderRequestDefDecorationMixin from './createOrderRequestDefDecorationMixin';
import dataLayerMixin from './dataLayer.mixin.js';
import recaptchaImportMixin from '@/plugins/reCaptcha';
import productPricesMixin from './productPrices';
const Cookie = process.client ? require('js-cookie') : undefined;

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { partsColors } from './utils';
import { sortByMediaTypeNames } from '@/utils/productImages';
import { mediaHasClassTypeName } from '../utils/media';
import { CODE_MESSAGES } from '../constants/index.js';

function sleep(time = 1000) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

function artWorkFile(allMedia = []) {
  const uniqueMediaMap = new Map();
  allMedia.forEach((media) => {
    if (!uniqueMediaMap.has(media._id)) {
      uniqueMediaMap.set(media._id, media);
    }
  });

  const mediaList = Array.from(uniqueMediaMap.values());

  const attachLogo = mediaList.find((e) => e.automaticallyAttachLogo);
  const logos = attachLogo ? [attachLogo] : mediaList.filter((e) => mediaHasClassTypeName(e, 'Logo'));

  return logos.map((logo) => {
    const item = {
      ...logo,
      ...logo.ArtworkFileArray?.find((e) => e),
    };
    return {
      ...item,
      fileType: item.artworkType,
      fileThumbLocation: item.urlSmall || item.url,
      fileLocation: item.url || item.urlOrigin || item.fileLocation,
      fileName: item.fileName || item.url.split('/').pop(),
    };
  });
}

function checkOrderDataArtworkFiles(orderData, productData, selectedPart, allPartsSizes) {
  if (!orderData.artworkFiles.length) {
    let logos = artWorkFile(
      (selectedPart?.mediaList || []).filter(
        (e) => e.productPart === selectedPart?._id || selectedPart?.mediaListId?.includes(e._id)
      )
    );

    if (!logos.length) {
      logos = artWorkFile(selectedPart?.mediaList || []);
    }
    if (!logos.length) {
      const partMediaList = productData.parts.find((p) => p._id === selectedPart?._id)?.mediaList;
      if (partMediaList) {
        logos = artWorkFile(partMediaList);
      }
    }

    if (!logos.length) {
      logos = artWorkFile(
        (allPartsSizes || [])
          .map((e) => productData.parts.find((p) => p._id === e.partId)?.mediaList || [])
          .reduce((a, b) => [...a, ...b], [])
      );
    }

    if (logos.length > 0) {
      orderData.artworkFiles = logos;
    }
  }
}

const createOrderRequestMixin = {
  mixins: [
    productPricesMixin,
    productViewMixin,
    recaptchaImportMixin,
    createOrderRequestDefDecorationMixin,
    dataLayerMixin,
  ],
  data() {
    return {
      loadingProductData: false,
      skipMinConfigType: false,
      sizesDefaultValues: {},
      selectedColor: '',
      updatedParts: Date.now(),
      updatedAt: Date.now(),
      deletedColorAt: Date.now(),
      loadedPrices: false,
      skeletonLoaded: false,
      loaded: false,
      additionalDecorations: this.productData?.additionalDecoration?.decorations || [],
      part: {
        id: '',
        color: '',
      },
      showDate: false,
      chooseColorOnInit: true,
      loadOnInit: true,
      isLimitReached: false,
      redeemedCodeData: null,
      selectedSubParts: [],
      selectedPartsQuantities: [],
      selectedSubPartsByIndex: [],
      selectedParts: [{ decorations: [] }],
      selectedPart: { pricing: [] },
      orderData: {
        quantity: '',
        artworkFiles: [],
        customerComments: {},
        CustomerPhoneNumbers: [
          { countryCode: '1', countryISO: this.$store.getters.DEFAULT_COUNTRY_CODE, phoneType: 'Cell' },
        ],
      },
    };
  },
  props: {
    productData: Object,
    preselectedData: Object,
  },
  async created() {
    try {
      if (this.loadOnInit) {
        await this.loadProductData();
      }
      this.$emit('onLoad');
    } catch (e) {
      console.log(e);
    }
  },
  watch: {
    orderArtworkFiles() {
      this.$emit('change-artworks', this.orderArtworkFiles);
    },
    selectedPartsQuantities: {
      handler(val) {
        if (this.orderData) {
          this.orderData.quantity = val.map((i) => parseInt(i)).reduce((a, b) => a + b, 0);
        }
      },
      deep: true,
    },
    selectedSubPartsByIndex: {
      handler(val) {
        this.selectedSubParts = [].concat.apply([], val); // [[sizes],[sizes],[sizes]]
      },
      deep: true,
    },
  },
  methods: {
    loadedMoreParts() {
      this.loadProductData();
      this.updatedParts = Date.now();
    },
    async loadProductData() {
      let order;
      try {
        const parts =
          this.availablePartsColors && this.availablePartsColors.length
            ? this.availablePartsColors
            : this.productData.parts;
        const { partId, part, orderId, poId, cartModalType } = this.preselectedData || this.$route.query;
        order = { partId, part, orderId, poId, cartModalType };
        let { quantity } = this.preselectedData || this.$route.query;
        if (cartModalType) this.cartModalType = cartModalType;
        if (!quantity) {
          quantity = this.minQuantity;
        }
        const separator = '_';
        const partsWithQuantities = Array.isArray(part) ? part : part ? [part] : [`${partId}${separator}${quantity}`];
        const orderParts = partsWithQuantities.map((partString) => {
          const partData = partString.split(separator);
          const quantity = partData.pop();
          const productPart = this.productData.parts.find((i) => partData.join(separator).includes(i.partId));
          const size = productPart && productPart.ApparelSize && productPart.ApparelSize.label_size;
          if (productPart && !productPart.image && productPart?.mediaList?.length) {
            const images = sortByMediaTypeNames(productPart.mediaList, this.storeInformation.partImagesOrder);
            productPart.image = images[0];
          }
          return {
            ...productPart,
            quantity,
            size,
          };
        });
        const orderColors = _.groupBy(orderParts, 'colorName');
        let totalQuantity = orderParts
          .filter((e) => e?.quantity)
          .map((i) => parseInt(i.quantity.split('-')[i.quantity.split('-').length - 1]))
          .reduce((a, b) => a + b, 0);
        let partsOptions = partsColors(this.productData.defaultPartGroup || '1', parts);
        let allSizes = _.groupBy(_.uniqBy(this.productData.parts, 'partId'), 'colorName');
        this.checkProductParts();
        const mediaIsInList = (list, item) => list.some((media) => media._id === item._id);
        let selectedPart =
          partsOptions.find((e) => e.pricing.find((e) => e.price)) ||
          partsOptions.find((e) => e.defaultPart) ||
          partsOptions[0];
        const logoMedia = parts
          .filter((part) => part?.mediaList?.length)
          .flatMap((part) => part?.mediaList)
          .filter((media) => mediaHasClassTypeName(media, 'Logo'));
        if (selectedPart) {
          selectedPart.mediaList = [
            ...selectedPart?.mediaList,
            ...logoMedia.filter((media) => !mediaIsInList(selectedPart?.mediaList, media)),
          ];
        }
        if (this.productData) {
          this.checkProductParts();
          Object.keys(orderColors)
            .filter((i) => i !== 'undefined')
            .forEach((colorName, index) => {
              try {
                const correctPartId = partId || orderColors[colorName][0].partId;
                const partById = parts.find((p) => p.partId === correctPartId);
                if (partById) selectedPart = partById;
                if (selectedPart) {
                  this.$emit('selectedPart', selectedPart);
                }
                const isApparel = orderColors[colorName].some((i) => i.size);
                if (isApparel) {
                  allSizes[colorName] = allSizes[colorName].map((partWithSize) => {
                    const size = orderColors[colorName].find((j) => j.partId === partWithSize.partId);
                    const quantity = (size && size.quantity) || 0;
                    return {
                      ...partWithSize,
                      quantity,
                    };
                  });
                  this.onChangeSizes(allSizes[colorName], index);
                } else {
                  this.onChangePartQuantity(orderColors[colorName][0].quantity, index);
                }
                this.onChangeSelectedPart(orderColors[colorName][0], index);
              } catch (e) {
                console.log(e);
              }
            });
          this.productData.decorations.sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1));
        }
        if (selectedPart) this.chooseColor(selectedPart, true, undefined, false);
        if (this.loggedInCustomer) {
          await this.fillCustomerData();
        }
        if (orderId) {
          const { data } = await this.$axios.get(`/orders/${orderId}/purchaseOrders/${poId}?childPOArtworks=true`);
          const orderData = {
            ...data,
            artworkFiles: (data.artworkFiles || []).filter((a) => a.title !== 'Designer export'),
          };
          try {
            if (this.productData.loadedOrderLocations) throw 'already loaded';
            this.productData.loadedOrderLocations = 1;

            const locations =
              data.LineItemArray?.length &&
              data.LineItemArray[0].Configuration[0].LocationArray.filter((e) => e.locationHashId);

            if (locations.length) {
              const ids = [...new Set(locations.map((e) => e.locationHashId))];
              const locationList =
                (data.locations &&
                  this.productData.locations.filter((e) => data.locations.map((e) => e._id).includes(e._id))) || //Only for decoration products
                (
                  await Promise.all(
                    ids.map(async (l) => {
                      return (await this.$axios.get(`/products/${this.productData._id}/locations?locationId=${l}`)).data
                        .locations;
                    })
                  )
                ).flat();
              locations.forEach((loc) => {
                const locItem = locationList.find((e) => e._id === loc.locationHashId);
                const { decorations, ...location } = locItem;
                // location.decorations = [];
                // console.log(location.decorations, loc.DecorationArray[0], this.productData.locations);
                const selectedDecoration =
                  decorations.find((e) => loc.DecorationArray[0].decorationId === e._id) ||
                  decorations.find((e) => loc.DecorationArray[0].decorationName === e.name);
                selectedDecoration.location = location;
                this.productData.additionalDecoration.decorations.push({
                  _id: this.productData._id,
                  additional: 1,
                  poLocation: loc,
                  artworkFiles: loc.DecorationArray[0].Artwork.ArtworkFileArray,
                  TypesetArray: loc.DecorationArray[0].Artwork.TypesetArray,
                  parts: this.productData.parts,
                  supplierId: this.productData.supplierId,
                  decorationProducts: this.productData.decorationProducts,
                  selectedDecorationProduct:
                    this.productData.selectedDecorationProduct &&
                    JSON.parse(JSON.stringify(this.productData.selectedDecorationProduct)),
                  setUpChargePrice: 0,
                  selectedDecoration: JSON.parse(JSON.stringify(selectedDecoration)),
                  defaultDecoration: JSON.parse(JSON.stringify(selectedDecoration)),
                  defaultLocation: JSON.parse(JSON.stringify(location)),
                  locations: [locItem],
                  customerComments: loc.customerComments,
                });
              });
            }
          } catch (e) {
            console.log(e);
          }

          this.orderData = Object.assign({}, this.orderData, orderData);
          if (!this.productData) {
            this.selectedPart = this.orderData.LineItemArray[0].PartArray[0];
            this.part.id = this.selectedPart.partId;
          }
          this.showDate = !!this.orderData.customerComments.dueDate;
          totalQuantity = data.LineItemArray.map((e) =>
            e.PartArray.map((e) => e.Quantity.value).reduce((a, b) => a + b, 0)
          ).reduce((a, b) => a + b, 0);
          if (this.selectedPart && this.selectedPart.ApparelSize && !this.allPartsSizes.length)
            this.allPartsSizes = data.LineItemArray[0].PartArray.map((el) => ({
              ...el.ApparelSize,
              quantity: el.Quantity.value,
            }));
          await this.loadOrderParts();
        }
        this.$set(this.orderData, 'quantity', totalQuantity);
        if (this.$route.path.match('/sample/') || this.cartModalType === 'Sample') {
          if (this.productPrices.find((el) => el.configurationType === 'Blank')) {
            this.onSelectedConfigurationType('Blank');
          }
          this.$set(this.orderData, 'quantity', 1);
        } else {
          this.$set(this.orderData, 'quantity', totalQuantity);
        }
        if (this.selectedPart) {
          this.selectedPart.sizes = [];
        }
        this.loaded = true;
        this.$emit('onLoaded', this.orderData);
      } catch (e) {
        console.log('----e', order, this.productData?.slug, location.href, e);
      }
    },
    async loadOrderParts() {
      try {
        let parts = this.preselectedData?.parts || this.$route.query?.parts || this.$route.query?.part || '';

        if (Array.isArray(parts)) {
          parts = parts.map((e) => e.split(',')).flat();
        }
        if (typeof parts === 'string') parts = parts.split(',');
        const originParts = parts;
        parts = parts.map((e) => e.split('_')[0]);
        const selected = this.selectedParts.map((e) => e.partId);
        parts = parts.filter((e) => !selected.includes(e));
        const partList = [];
        const _parts = (
          await Promise.all(
            [...new Set(parts)].map(async (partId) => {
              const res = await this.$api.products.getProductBySlug(this.product.slug, { partId });
              partList.push({ partId, parts: res.parts });
              return res;
            })
          )
        )
          .map((e) => {
            return e.parts;
          })
          .flat();
        this.productData.parts.push(..._parts);
        this.selectedParts.push(
          ...parts.map((e) => {
            const part = _parts.find((p) => e === p.partId);
            const res = {
              ...part,
              sizes: [],
              decorations: [],
            };
            let used = 0;
            for (let i = 0; i < partList.length; i++) {
              if (partList[i].partId === e) {
                res.sizes = partList
                  .splice(i--, 1)
                  .map((e) => e.parts)
                  .flat()
                  .map((el) => {
                    const size = {
                      ...el.ApparelSize,
                      inventoryQuantity: el.inventoryQuantity,
                      partId: el._id,
                      quantity: null,
                      prices: el.pricing,
                    };
                    if (!used) {
                      for (let d = 0; d < originParts.length; d++) {
                        const original = originParts[d];
                        let match = original.match(el.partId);
                        if (match) {
                          size.quantity = parseInt(original.split('_')[2]);
                          originParts.splice(d--, 1);
                          used = 1;
                          break;
                        }
                      }
                    }

                    return size;
                  });
                break;
              }
            }
            return res;
          })
        );
      } catch (e) {
        console.log('loadOrderParts--:', e);
      }
    },
    onLoadProduct() {
      if (this.selectedPart) this.selectedPart.sizes = [];
      this.checkProductParts();
      if (this.productView.decoration && this.productView.decoration.show) {
        this.selectedDecoration = this.productData.selectedDecoration;
      }
      this.$set(this.orderData, 'quantity', this.productData.minQuantity);
      this.checkOrderData();
      if (this.selectedPart && this.selectedPart.image) {
        this.currentImageIndex = this.productImages.findIndex(
          (el) => el.src === this.selectedPart.image.url || el.src === this.selectedPart.image.urlSmall
        );
      }
      this.updated = Date.now();
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.$refs.carousel) {
            this.$refs.carousel.slideTo(this.currentImageIndex);
          }
          if (this.selectedPart && this.selectedPart.image) {
            this.productImage = this.selectedPart.image.url;
          }
        });
      });
    },
    isOpenModalWhenAdding(cartType) {
      const carts = this.storeInformation?.carts || [];
      const storeCart = carts.find((i) => i.type?.toLowerCase() === cartType?.toLowerCase());
      return storeCart?.openModalWhenAdding;
    },
    checkRedemptionOrdersAmount() {
      const storedCode = Cookies.get('REDEMPTION_CODE');
      this.redeemedCodeData = storedCode ? JSON.parse(storedCode) : null;
      if (this.redeemedCodeData && this.redeemedCodeData.storeId.toString() === this.storeInformation._id.toString()) {
        const ordersAmount = parseFloat(this.redeemedCodeData.ordersAmount);
        const multipleCustomerUse = this.redeemedCodeData.conditions?.multipleCustomerUse;
        const appliedBy = this.redeemedCodeData.appliedBy?.includes(this.loggedInCustomer?._id.toString());
        if (!multipleCustomerUse && appliedBy) {
          this.notificateError(CODE_MESSAGES.applied);
          return false;
        }
        if (ordersAmount) {
          const addedOrdersToCart = this.orders.reduce((acc, order) => acc + order.quantity, 0);
          if (
            (addedOrdersToCart && addedOrdersToCart >= ordersAmount) ||
            (this.orderData && this.orderData.quantity > ordersAmount)
          ) {
            this.isLimitReached = true;
            this.notificateError(CODE_MESSAGES.limitReached);
            return false;
          }
        }
      }
      return true;
    },
    async createCartOrder() {
      try {
        if (!this.checkRedemptionOrdersAmount()) return;
        this.orderData.fromProductView = true;
        checkOrderDataArtworkFiles(this.orderData, this.productData, this.selectedPart, this.allPartsSizes);
        const { createdOrder, purchaseOrder } = await this.createOrderRequest('cart');
        const po = createdOrder.PurchaseOrders.find(
          (e) => e.friendlyPurchaseOrderDetailId === purchaseOrder.friendlyPurchaseOrderDetailId
        ); //filter((e) => !e.parentPoId).pop();
        const { carts = [] } = this.storeInformation;
        const cart = carts.find((i) => i.type === 'Cart');
        let path = this.orderData.orderId ? '/cart' : '/cartitemsadded';
        let addQuery = true;
        if (cart) {
          if (cart.skipAddToCartPage) {
            addQuery = false;
            path = `/${cart.route}`;
          }
        }
        const sidebarCartType = this.isOpenModalWhenAdding('cart');
        if (addQuery || sidebarCartType) {
          await this.onAddToCart(() => {
            this.$router.push({
              path,
              query: addQuery && {
                orderId: createdOrder.orderId,
                poId: po.friendlyPurchaseOrderDetailId,
                productId: po.LineItemArray[0].productId,
                total: (parseFloat(po.productTotal) - parseFloat(po.discountTotal)).roundCeil(),
              },
            });
          });
        } else {
          this.$router.push({
            path,
          });
        }

        this.notificateSuccess('Item Added To Cart');
      } catch (e) {
        if ((e?.data?.message || e?.message || e || '').toLowerCase().match(/recaptcha is not a function/gi)) {
          return window.location.reload();
        }
        throw e;
      }
    },
    async onAddToCart(callbackOnNotSidebar) {
      try {
        const sidebarCartType = this.isOpenModalWhenAdding('cart');
        if (sidebarCartType) {
          await this.$store.dispatch('sidebars/TOGGLE_CART_SIDEBAR', 'Cart');
        } else {
          await callbackOnNotSidebar();
        }
      } catch (e) {
        console.log('onAddToCart', e);
      }
    },
    checkOrderData() {
      if (this.showDecoration) {
        if (this.defaultDecoration) {
          this.$set(this.orderData, 'decorationName', this.defaultDecoration.name);
          this.$set(
            this.orderData,
            'decorationId',
            this.defaultDecoration.decoration_id || this.defaultDecoration.decorationId
          );
        }
        if (this.defaultLocation) {
          this.$set(this.orderData, 'locationName', this.defaultLocation.name);
          this.$set(
            this.orderData,
            'locationId',
            this.defaultLocation?.location_id || this.defaultLocation?.locationId
          );
        }
        if (this.setUpChargePrice) {
          this.$set(
            this.orderData,
            'charge',
            this.setUpCharge || (this.defaultDecoration && this.defaultDecoration.selectedCharge)
          );
        }
      } else {
        ['charge', 'locationName', 'decorationName'].forEach((key) => delete this.orderData[key]);
      }
    },
    onChangeSizes(sizes, index = 0) {
      let partId;
      let usedQuantity;
      sizes.forEach((size) => {
        const part = this.productData.parts.find((e) => e._id === size.partId);
        if (part?.disabled && size.quantity) {
          if (!usedQuantity) {
            usedQuantity = size.quantity;
          }
          size.quantity = null;
          size.count = null;
          partId = size.partId;
        }
      });
      if (partId && !sizes.find((e) => e.quantity)) {
        for (let i = 0; i < sizes.length; i++) {
          if (sizes[i - 1]?.partId === partId) {
            const part = this.productData.parts.find((e) => e._id === sizes[i].partId);
            if (part.disabled) {
              partId = sizes[i].partId;
            } else {
              sizes[i].quantity = usedQuantity;
              break;
            }
          }
        }
      }
      while (this.partSizes.length) {
        this.partSizes.shift();
      }
      this.partSizes.push(...sizes);
      const partQuantity = sizes
        .map((el) => parseInt(el.quantity || el.count))
        .filter((e) => e)
        .reduce((a, b) => a + b, 0);
      this.$set(this.selectedPartsQuantities, index, partQuantity);
      this.$set(this.partsSizesByIndex, index, sizes);

      this.sizesDefaultValues = {}; // sizes.reduce((qties, size) => ({ ...qties, [size.label_size]: size.quantity }), {});

      this.updatedAt = Date.now();
    },
    onClearPartsSizes() {
      this.partsSizesByIndex = [];
    },
    onChangePartQuantity(quantity, index) {
      this.$set(this.selectedPartsQuantities, index, quantity);
    },
    onSelectSubPart({ list, part }, index = 0) {
      this.$set(this.selectedSubPartsByIndex, index, list);
      if (part && part.image && part.partGroup === (this.productData.defaultPartGroup || '1'))
        this.chooseImage(part.image.url, null, true);
      this.updatedAt = Date.now();
    },
    clearSelectedParts() {
      this.selectedParts = [];
      this.selectedPartsQuantities = [];
    },
    setPartsSizesQuantity(quantity) {
      this.allPartsSizes.forEach((i) => {
        i.quantity = quantity;
      });
    },
    onChangeSelectedPart(part, index) {
      this.$set(this.selectedParts, index, part);
      this.$emit('selectedPart', part);
    },
    onRemoveColor(index) {
      this.selectedParts.splice(index, 1);
      this.partsSizesByIndex.splice(index, 1);
      this.selectedSubPartsByIndex.splice(index, 1);
      this.selectedPartsQuantities.splice(index, 1);
      this.deletedColorAt = Date.now();
    },
    async fillCustomerData() {
      try {
        const { data } = await this.$axios.get(`/customers/${this.loggedInCustomer._id}`);
        this.orderData = Object.assign({}, this.orderData, data);
        if (this.orderData.CustomerPhoneNumbers && this.orderData.CustomerPhoneNumbers.length) {
          this.orderData.CustomerPhoneNumbers = [this.orderData.CustomerPhoneNumbers.pop()];
        } else {
          this.orderData.CustomerPhoneNumbers = [{}];
        }
        if (!this.orderData.artworkFiles) this.orderData.artworkFiles = [];
        if (this.orderData.companyId) {
          this.orderData.companyName =
            typeof this.orderData.companyId === 'string'
              ? (await this.$axios.get(`/companies/${this.orderData.companyId._id}`)).data.companyName
              : this.orderData.companyId.companyName;
        }
      } catch (e) {}
    },
    async chooseImage(src, currentImageIndex, isSubPart, callback) {
      let colorsWithImages = this.productImages;
      let selected;
      if (typeof currentImageIndex !== 'undefined') selected = colorsWithImages[currentImageIndex];
      if (!colorsWithImages || !colorsWithImages.length)
        colorsWithImages = [].concat.apply(
          [],
          this.productData.parts.map((part) => {
            if (part?.mediaList?.length) {
              return part.mediaList.map((image) => {
                return {
                  ...image,
                  src: image.url,
                  srcSmall: image.urlSmall,
                  color: part.colors.map((color) => color.name).join('/') || part.partId,
                };
              });
            }
            return [];
          })
        );
      if (!selected)
        selected = colorsWithImages.find(
          (colImg) =>
            (colImg.part_id || colImg.partId) &&
            [colImg.url, colImg.urlSmall, colImg.src, colImg.srcSmall].includes(src)
        );
      if (!selected)
        selected = colorsWithImages.find((colImg) =>
          [colImg.url, colImg.urlSmall, colImg.src, colImg.srcSmall].includes(src)
        );
      if (!selected) selected = this.productData.imageDefault;
      if (selected) {
        this.$nextTick(() => {
          this.$set(this.productData, 'imgUrl', selected.url);
          this.$set(this.productData, 'imgUrlSmall', selected.urlSmall);
          this.productImage = this.productData.imgUrl;
        });
        await this.checkSelectedPart(selected, isSubPart, currentImageIndex);
      }
      if (callback) callback();
    },
    async checkSelectedPart(selected, isSubPart, currentImageIndex) {
      const selectedPart = this.selectedPart;
      let colorName = selected.color || selected.caption;

      let productColor = this.productData.parts.find(
        (part) => (selected.partId && selected.partId === part.partId) || (colorName && part.colorName === colorName)
      );
      let selectedPartItem = 0;
      if (!productColor && this.$refs.partsColor && selected.partId) {
        const part = this.productData.parts.find((part) => part._id === selected.productPart);
        productColor = await this.$refs.partsColor.chooseColor(part, null, currentImageIndex);
        selectedPartItem = productColor;
      }

      if (!productColor) return;
      if (!colorName) colorName = productColor.colors.map((color) => color.name).join('/') || productColor.partId;
      if (!colorName) return;
      this.selectedColor = productColor.colorNameRelativeLabel;

      if (!isSubPart && productColor.partGroup === (this.productData.defaultPartGroup || '1')) {
        if (selectedPart?._id !== productColor?._id) {
          if (this.$refs.partsColor) {
            this.$refs.partsColor.mainPart = productColor;
            this.$refs.partsColor.chooseColor(productColor, selectedPartItem, currentImageIndex);
          } else {
            this.chooseColor(productColor, true, currentImageIndex);
          }
        }
      } else {
        if (typeof currentImageIndex === 'undefined') {
          const images = this.productImages;
          if (selected.image) {
            this.currentImageIndex = images.findIndex(
              (el) => el.src === selected.image.url || el.src === selected.image.urlSmall
            );
          } else if (selected.src) {
            this.currentImageIndex = images.findIndex((el) => selected.url === el.url);
          }
          if (this.currentImageIndex < 0)
            this.currentImageIndex = this.productData.parts.findIndex((el) => el.partId === selected.partId);
        } else {
          this.currentImageIndex = currentImageIndex;
        }
        this.focusCarusel();
      }
    },

    focusCarusel() {
      if (this.$refs.carousel) {
        setTimeout(() => {
          const slideTo = this.currentImageIndex - (this.currentImageIndex % this.$refs.carousel?.itemsToShow);
          this.$refs.carousel.slideTo(slideTo);
        }, 100);
      }
    },
    uniqueParts(_parts) {
      const exist = [];
      let parts = _parts.filter(
        (el) =>
          (el.partGroup === (this.productData.defaultPartGroup || '1') || typeof el.partGroup === 'undefined') &&
          el.status !== 'discontinued'
      );
      parts = [...parts.filter((el) => el.partId || el.part_id), ...parts.filter((el) => el.partId || el.part_id)];
      return parts.filter((el) => {
        if (el.colorName) {
          if (exist.indexOf(el.colorName.toLowerCase()) > -1) {
            return false;
          } else {
            exist.push(el.colorName.toLowerCase());
            return true;
          }
        }
      });
    },
    isValidImage(url) {
      return /\.(jpg|jpeg|png|gif)$/i.test(url);
    },
    chooseColor(color, changeMain) {
      if (!color?.sizes) {
        color.sizes = [];
      }
      if (!color.ApparelSize && this.allPartsSizes) {
        this.$set(this.partsSizesByIndex, 0, []);
      }
      this.selectedPart = color;
      this.part.color = color.colorName;
      this.part.id = color.partId;
      if (this.selectedPart) this.selectedColor = this.selectedPart.colorNameRelativeLabel;
      if (color.image) {
        this.$set(
          this.productData,
          'imgUrl',
          this.isValidImage(color.image.url) ? color.image.url : color.image.urlSmall
        );
      }
      this.$emit('selectedPart', color);
    },
    async trackDevice({ _id: orderId, customerId }) {
      const info = await getIpInfo();
      const data = { ...info, orderId, customerId };
      return this.$axios.post('orderdevices', data);
    },
    async createOrderRequest(type, opt = {}, loadCart = true) {
      try {
        const recaptchaToken = await this.getReCaptchaToken();
        this.checkOrderData();
        if (this.loggedInCustomer && !this.orderData.customerId) {
          await this.fillCustomerData();
        }
        const {
          productName,
          noChargeStore,
          productId,
          supplierId,
          imgUrl,
          productImageSrc,
          decorationProducts = [],
          indexId,
        } = this.productData || this.orderData.LineItemArray[0];
        delete this.orderData.productImageSrc;
        delete this.orderData.productTotal;
        const partsInfo = this.totalSubPartsPrice;
        const hasSizes = !!this.selectedPart.ApparelSize;
        const totalPrices = hasSizes ? this.totalPriceWithSizes : this.totalPrice;
        const isDecorationPrices = this.selectedConfigurationType === 'Decorated';
        const withCharge = type !== 'sample';
        let charge = withCharge ? this.setUpChargePriceVal : 0;
        if (isDecorationPrices && decorationProducts.length) {
          if (totalPrices.calWithOriginPrices) {
            Object.assign(totalPrices, totalPrices.calWithOriginPrices);
          }
          if (totalPrices.parts) {
            totalPrices.parts.forEach((part) => {
              Object.assign(part, part.partCalWithOriginPrices);
            });
          }

          // if (
          //   !(this.selectedDecoration && this.productData && this.selectedDecoration.productId === this.productData._id)
          // ) {
          //   console.log('---totalPrices', this.selectedDecoration, this.productData);
          //   totalPrices.setUpChargePrice = 0; //can be 0 if charge was from decorated product or product own charge
          //   if (totalPrices.parts) totalPrices.parts.forEach((el) => (el.setUpChargePrice = 0));
          // }

          // console.log('---totalPrices', totalPrices);
          charge = totalPrices.setUpChargePrice;
        }

        let parts = totalPrices.parts || [];
        if (this.setUpChargePriceAdditional > 0) {
          this.orderData.charges = (this.selectedDecoration?.charges || []).filter((e, index) => index > 0);
          if (!this.orderData.charges.length) {
            this.orderData.charges = (this.selectedDecoration?.charges || []).filter((e, index) => e.type !== 'setup');
          }
        }

        const defReqBody = {
          ...opt,
          ...totalPrices,
          ...this.orderData,
        };

        try {
          if (
            !parts.length &&
            ['cart', 'configured'].includes(type) &&
            ['unitPrice', 'unitCost', 'margin'].filter((e) => defReqBody[e]).length < 3 //missing part details
          ) {
            const part = JSON.parse(JSON.stringify(this.selectedPart));
            const minPrice = this.minPartPriceInfo(part, this.orderData.quantity);
            if (!part.unitPrice) part.unitPrice = minPrice.unitPrice;
            if (!part.unitCost) part.unitCost = minPrice.unitCost;
            if (!part.margin) part.margin = minPrice.margin;
            parts.push(part);
          }
        } catch (e) {
          console.log(e);
        }

        totalPrices.productTotal -= charge;
        if (hasSizes) {
          if (totalPrices.parts) {
            totalPrices.parts.forEach((el, index) => {
              if (index === 0) el.productTotal -= charge;
            });
          }
        }
        if (partsInfo && partsInfo.parts.length) {
          // if (!isNaN(partsInfo.totalPrice)) totalPrices.productTotal -= partsInfo.totalPrice; //Do not need to include the subparts total
          if (type === 'configured') {
            parts = [].concat.apply(
              [],
              parts.map((i) => {
                const subParts = partsInfo.parts.filter((j) => j.afterPartId === i._id);
                return [i, ...subParts];
              })
            );
          } else {
            parts = [
              {
                ...this.selectedPart,
                ...totalPrices,
              },
              ...partsInfo.parts,
            ];
          }
        }
        let decorProducts = [];
        if (isDecorationPrices) {
          decorProducts = decorationProducts
            .map((product) => {
              const parts = product.parts.map((el) => {
                let pricing = el.pricing.filter((price) => price.configurationType === this.selectedConfigurationType);
                if (!pricing.length) pricing = el.pricing.filter((price) => !price.configurationType);
                return {
                  ...el,
                  pricing,
                };
              });
              let res = {
                ...product,
                parts: parts.filter((el) => product.selectedPart === el._id),
              };
              if (!res.parts.length) {
                res.parts = parts.filter((el) => product.selected);
              }
              if (!res.parts.length && parts.length) {
                res.parts = [parts[0]];
              }
              return res;
            })
            .filter(
              (e, index) =>
                e.parts.length &&
                (!this.selectedDecoration ||
                  this.selectedDecoration._id === -1 ||
                  (e.selectedDecoration && e.selectedDecoration._id === this.selectedDecoration._id) ||
                  e?.locations.find((e) => e.decorations.map((e) => e._id).includes(this.selectedDecoration._id)))
            );
          if (decorationProducts && decorationProducts.length && !decorProducts.length) {
            throw `There is no ${this.selectedConfigurationType} pricing configured for the decorated product.`;
          }
        }
        const { poId } = this.preselectedData || this.$route.query;
        const { categories, facets } = this.productData;
        const { carts = [] } = this.storeInformation;
        let cartType =
          type === 'quote'
            ? 'Quote'
            : type === 'artproof'
            ? 'Proof'
            : type === 'sample'
            ? 'Sample'
            : type === 'box'
            ? 'Box'
            : 'Cart';
        if (this.$route.path.toLowerCase().match('/largequote/')) {
          cartType = null;
        }
        const storeCart = carts.find((i) => i.type === cartType);

        let cartOrders = Cookie.get('cartOrders') || undefined;
        cartOrders = cartOrders ? JSON.parse(cartOrders) : [];
        const storageCartOrder = storeCart ? cartOrders.find((i) => i.type === cartType) : cartOrders[0];

        checkOrderDataArtworkFiles(this.orderData, this.productData, this.selectedPart, this.allPartsSizes);

        const reqBody = {
          order: {
            ...opt,
            ...totalPrices,
            ...this.orderData,
            additionalDecorations: this.additionalDecorations
              .map((e) => {
                if (!e.selectedDecoration) return;
                const res = {
                  decorationName: e.selectedDecoration.name,
                  decorationId:
                    e.selectedDecoration.decoration_id || e.selectedDecoration.decorationId || e.selectedDecoration._id,
                  locationId: e.selectedDecoration.location.location_id || e.selectedDecoration.location.locationId,
                  locationHashId: e.selectedDecoration.location._id,
                  locationName: e.selectedDecoration.location.name,
                  charge: e.setUpCharge || e.selectedDecoration?.selectedCharge,
                  customerComments: e.customerComments,
                  TypesetArray: e.TypesetArray,
                  artworkFilesV1: e.artworkFiles,
                  charges: [],
                };
                if (this.setUpChargePriceAdditionalL(e.selectedDecoration) > 0) {
                  res.charges = this.setUpChargePriceAdditionalItem(e.selectedDecoration);
                  if (res.charges) {
                    res.charges = [res.charges];
                  } else {
                    res.charges = (e.selectedDecoration?.charges || []).filter((e, index) => index > 0);
                    if (!res.charges.length) {
                      res.charges = (e.selectedDecoration?.charges || []).filter((e, index) => e.type !== 'setup');
                    }
                  }
                }
                return res;
              })
              .filter((e) => e),
            storeId: this.storeInformation._id,
            ...(supplierId && { supplierId: supplierId._id || supplierId }),
            poId,
            productName,
            noChargeStore,
            decorationProducts: decorProducts
              .map((decorProduct) =>
                this.calcTotalDecorated({
                  ...decorProduct,
                  supplierId: decorProduct.supplierId?._id
                    ? decorProduct.supplierId
                    : { _id: decorProduct.supplierId, psCode: decorProduct.supplierCode },
                  categories,
                  facets, //get origin product data for discount
                  parts: decorProduct.parts.map((part) => {
                    return {
                      ...decorProduct.parts[0],
                      quantity: parts.map((el) => el.quantity).reduce((a, b) => a + b, 0) || this.orderData.quantity,
                    };
                  }),
                })
              )
              .slice(0, 1)
              .map((el) => ({
                ...el,
                total: el.productTotal,
                productTotal: el.productTotal - (withCharge ? this.setUpChargePriceVal : 0),
              })), //only first one
            productId,
            cardOrderId: storageCartOrder?._id,
            localProduct: this.productData && this.productData._id,
            productImageSrc: imgUrl || productImageSrc,
            parts: parts.map((el) => ({
              ...el,
              partId: el.partId,
              partColor: el.colorName,
            })),
            partId: this.part.id,
            partSKU: this.part.indexId,
            TypesetArray: this.typesetData || [],
            productSKU: indexId,
            partColor: this.part.color,
            recaptchaToken,
            fromProductView: this.$route.path.match('/products/') && type === 'configured' ? true : false,
          },
          source: location.pathname,
          cartType: storeCart ? storeCart.type : null,
        };
        if (this.storeInformation.allowNoPricing) {
          [
            'unitPrice',
            'unitCode',
            'price',
            'margin',
            'listPrice',
            'productTotalCost',
            'productTotal',
            'total',
            'productDiscount',
            'perEach',
          ].forEach((key) => {
            if (!reqBody.order[key] || isNaN(reqBody.order[key])) {
              reqBody.order[key] = 0;
            }
          });
        }
        // console.log('---reqBody', reqBody);
        // throw 'eer';
        if (this.productData.blankLocations?.find((e) => e.name)) {
          reqBody.order.locationName = this.productData.blankLocations[0].name || '';
          reqBody.order.locationId = this.productData.blankLocations[0]?.locationId || '';
        }
        if (this.selectedConfigurationType === 'Blank' && type !== 'sample') reqBody.poType = 'Blank';
        let data;
        try {
          const IS_DEV_ENV = window?.location.origin.match(/localhost|127.0.0.1/gi);
          if (IS_DEV_ENV) {
            await sleep(1500);
          }
          const response = reqBody.order.orderPOUid
            ? await this.$axios.put(`orders/${reqBody.order.orderId}/purchase-orders/${poId}/stores/${type}`, reqBody)
            : await this.$axios.post(`orders/store/${type}`, reqBody);
          data = response.data;
          if (
            cartOrders &&
            data?.createdOrder?._id &&
            cartOrders?.find((e) => e.type === cartType)?._id !== data.createdOrder._id
          ) {
            const existCart = cartOrders?.find((e) => e.type === cartType);
            if (existCart && existCart._id !== data.createdOrder._id) {
              existCart._id = data.createdOrder._id;
              Cookie.set('cartOrders', JSON.stringify(cartOrders));
            }
          }
        } catch (e) {
          const message = e?.response?.data?.message || e?.data?.message || e?.message || e || '';
          throw new Error(
            /Recaptcha|not allow orders|not accepting orders/.test(message) || message
              ? message
              : 'Cannot process order'
          );
        }
        this.onDataLaterAddToCart(reqBody, type);

        if (!this.loggedInCustomer && type && (type !== 'artproof' || cartType === 'Proof')) {
          if (!storageCartOrder) {
            cartOrders.push({ type: cartType, _id: data.createdOrder._id });
            Cookie.set('cartOrders', JSON.stringify(cartOrders));
          }
        }

        this.trackDevice(data.createdOrder);

        const customerId = this.loggedInCustomer && this.loggedInCustomer._id;
        if (data.createdOrder && data.createdOrder.orderId && loadCart)
          await this.$store.dispatch('cart/loadOrders', {
            orders: [data.createdOrder._id],
            customerId,
            storeInformation: this.storeInformation,
            forceLoad: true,
            addToExistsOrders: true,
          });
        return data;
      } catch (e) {
        console.log(e);
        if ((e?.data?.message || e?.message || e || '').toLowerCase().match(/recaptcha is not a function/gi)) {
          if (process.client) {
            window.location.reload();
          }
        }
        throw e;
      }
    },
    setUpChargePriceFind({ product, defaultDecoration, selectedDecoration }) {
      return product.decorationProducts.length > 1 && !selectedDecoration
        ? 0
        : parseFloat(defaultDecoration ? defaultDecoration.setupChargePrice || 0 : 0);
    },
  },

  computed: {
    ...mapGetters({
      loggedInCustomer: 'auth/loggedInUser',
      storeInformation: 'storeInformation',
      orders: 'cart/orders',
    }),
    backProductUrl() {
      const { partId } = this.$route.query;
      return `/products/${this.productData.slug}${partId ? `?partId=${partId}` : ''}`;
    },
    orderArtworkFiles() {
      return this.orderData.artworkFiles;
    },
    minPartDataInfo() {
      return this.minPartPriceInfo(this.selectedPart, null, this.skipMinConfigType);
    },
    minQuantity() {
      return this.minPartDataInfo?.minQuantity || 0;
    },
    minProductQuantity() {
      return this.minPartPriceInfo(this.selectedPart, 1, true).minQuantity;
    },
    defMinQuantity() {
      return this.isModal ? this.minQuantity : this.defMinQuantityV1;
    },
    defaultLocation() {
      if (this.defaultDecoration) {
        return this.defaultDecoration?.location || (this.product?.locations.length && this.product?.locations[0]);
      }
      return null;
    },
    setUpCharge() {
      return this.defaultDecoration
        ? this.defaultDecoration.setupCharge || this.defaultDecoration.selectedCharge
        : null;
    },
    hasError() {
      if (this.selectedPart.ApparelSize) {
        return (
          this.allPartsSizes &&
          this.allPartsSizes.map((el) => el.quantity).reduce((c, n) => parseFloat(c || 0) + parseFloat(n || 0), 0) <
            this.minQuantity
        );
      } else {
        return false;
      }
    },
    setUpChargePrice() {
      return this.setUpChargePriceFind({
        product: this.productData,
        defaultDecoration: this.defaultDecoration,
        selectedDecoration: this.selectedDecoration,
      });
    },
    productPrices() {
      const { partId } = this.orderData || this.$route.query;
      let activePart = this.productData.parts.find((part) => part.partId === partId);
      if (!activePart) {
        activePart = this.productData.parts[0];
      }
      return activePart.pricing;
    },
    totalPriceWithSizes() {
      return this.calcTotalWithSizes(this.productData.parts);
    },
    totalPrice() {
      const i = this.updatedAt;
      const parts = this.productData ? this.productData.parts : [];
      const { path } = this.$route;
      if (this.selectedParts.length && (1 || path.includes('designer') || path.includes('configureproduct'))) {
        return this.totalPriceByMultipleParts;
      }
      return this.calcTotal(this.selectedPart, parts);
    },
    totalPriceByMultipleParts() {
      const productParts = this.productData ? this.productData.parts : [];
      if (this.allPartsSizes.length) {
        return this.calcTotalWithSizes(productParts, false);
      }
      const parts = [];
      this.selectedParts.forEach((part, index) => {
        const response = this.calcTotal(part, productParts, true, false, this.selectedPartsQuantities[index]);
        parts.push({ ...response, ...part, quantity: this.selectedPartsQuantities[index] });
      });
      const findTotal = (field) => {
        return parts.map((i) => i[field]).reduce((a, b) => a + b, 0);
      };
      const excludedCharge = parts
        .map((i) => i.setUpChargePrice)
        .filter((i, index) => index > 0)
        .reduce((a, b) => a + b, 0); // charges sum except first item;

      const totalUnitCost = findTotal('unitCost');
      const totalUnitPrice = findTotal('unitPrice');

      return {
        parts,
        margin: ((totalUnitCost / totalUnitPrice) * 100).roundCeil(),
        productTotal: findTotal('productTotal') - excludedCharge,
        productTotalCost: findTotal('productTotalCost') - excludedCharge,
        productDiscount: findTotal('productDiscount'),
      };
    },
  },
  components: {
    flatPickr,
  },
};

export { createOrderRequestMixin as default };
