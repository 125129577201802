import { mediaHasClassTypeName, mediaHasClassTypeNames, mediaHasClassTypeId } from '@/utils/media';

const exludeImages = [
  {
    id: '1004',
    name: 'swatch',
  },
  {
    id: '4004',
    name: 'ArtTemplate',
  },
  {
    id: '4005',
    name: 'Marketing',
  },
];

let classTypIdImages = [
  {
    id: '1002',
    name: 'decorated',
  },
  {
    id: '1007',
    name: 'front',
  },
  {
    id: '1006',
    name: 'primary',
  },
];

export function imageOrderPriority(resImgs, imagesWithPartIdsID = [], options) {
  let imagesWithPartIds = [...imagesWithPartIdsID];
  const imagesTypes = options?.imagesClassTypesOrder
    ?.find((e) =>
      e.groups.find((g) => {
        try {
          const type_id = g.split('-');
          return resImgs.find((i) =>
            i?.classTypes.find(
              (c) =>
                g === c.class_type_id ||
                (type_id && type_id[0] <= c.class_type_id && type_id[1] && type_id[1] >= c.class_type_id)
            )
          );
        } catch (e) {}
      })
    )
    ?.groups.map((id) => ({ id }));
  const prioretyTypes = JSON.parse(JSON.stringify(imagesTypes || classTypIdImages)).map((e, index) => ({
    ...e,
    index,
  }));
  let tempImagesWithPartIds = prioretyTypes
    .map((el) => {
      const list = resImgs
        .filter((img) => img && imagesWithPartIds.indexOf(img.urlSmall) < 0 && mediaHasClassTypeId(img, el.id))
        .map((e) => ({
          ...e,
          index:
            prioretyTypes.find((p) => {
              try {
                const type_id = p.id.split('-');
                return e.classTypes.find(
                  (e) =>
                    e.class_type_id === p.id ||
                    (type_id && type_id[0] <= e.class_type_id && type_id[1] && type_id[1] >= e.class_type_id)
                );
              } catch (e) {}
            })?.index || prioretyTypes.length + 1,
        }))
        .sort((a, b) => a.index - b.index);
      imagesWithPartIds = [...imagesWithPartIds, ...list.map((el) => el.urlSmall)];
      return list;
    })
    .reduce((a, b) => [...a, ...b], []);

  const allLeft = resImgs.filter((img) => imagesWithPartIds.indexOf(img.urlSmall) < 0);
  const list = [...tempImagesWithPartIds, ...allLeft];
  let partsId = [];
  let listRs = [];
  const savedId = [];
  //grouped by partId
  for (let i = 0; i < list.length; i++) {
    const partId = list[i].partId || list[i].part_id || list[i].productPart;
    if (partId && partsId.indexOf(partId) < 0) {
      let partListRs = [];
      if (savedId.indexOf(list[i].urlSmall) < 0) {
        partListRs.push(list[i]);
        savedId.push(list[i].urlSmall);
        partsId.push(partId);
      }
      for (let j = 0; j < list.length; j++) {
        const partSameId = list[j].partId || list[j].part_id || list[j].productPart;
        if (partSameId === partId) {
          if (savedId.indexOf(list[j].urlSmall) < 0) {
            partListRs.push(list[j]);
            savedId.push(list[j].urlSmall);
            partsId.push(partSameId);
          }
        }
      }
      if (options.mediaTypePriority) {
        partListRs = sortByMediaTypeNames(partListRs, options.mediaTypePriority);
      }
      listRs.push(...partListRs);
    }
  }

  //all anothers
  for (let i = 0; i < list.length; i++) {
    if (savedId.indexOf(list[i].urlSmall) < 0) {
      listRs.push(list[i]);
    }
  }
  imagesWithPartIds = [...imagesWithPartIds, ...listRs.map((el) => el.urlSmall)];
  return {
    list: listRs,
    imagesWithPartIds,
  };
}

export function mediaList({ excludeType, onlyType, productParts, imageDefault }) {
  const parts = JSON.parse(JSON.stringify(productParts));
  const defaultImage = imageDefault && JSON.parse(JSON.stringify(imageDefault));
  let usedImageDefault = false;
  let partsId = [];
  const exist = {};
  const media = [].concat.apply(
    [],
    parts
      // .filter(el => (el.partGroup === '1' || typeof el.partGroup === 'undefined') && el.status !== 'discontinued')
      .map((part) => {
        const res = [];
        partsId.push(part.partId || part.part_id);
        if (!usedImageDefault && defaultImage) {
          usedImageDefault = true;
          res.push(defaultImage);
        }
        const exlude = exludeImages;
        for (let i = 0; i < part.mediaList.length; i++) {
          let img = part.mediaList[i];
          if (
            !img ||
            (img.type &&
              ((excludeType && excludeType.includes(img.type)) || (onlyType && !onlyType.includes(img.type)))) ||
            (!img.type && onlyType && onlyType.length)
          )
            continue;
          let isExclude = false;
          const matches = [];
          for (let j = 0; j < exlude.length; j++) {
            if (mediaHasClassTypeId(img, exlude[j].id)) {
              matches.push(1);
            }
          }

          isExclude =
            !img.url ||
            (Array.isArray(img.classTypes) && img.classTypes.length
              ? img.classTypes.length === matches.length
              : matches.length > 0);
          if (isExclude) continue;

          for (let d = 0; d < res.length; d++) {
            const img1 = res[d];
            if (img1.url === img.url) {
              if (img.partId || img.part_id) {
                Object.assign(img1, img);
              }
              img = null;
              break;
            }
          }
          if (img && !exist[img._id]) {
            exist[img._id] = 1;
            res.push(img);
          }
        }
        return res.map((image) => ({
          ...image,
          defaultPart: part.defaultPart,
          caption:
            !image.partId ||
            (image.partId && image.partId === part.partId) ||
            (image.color && part.colors.map((e) => e.name && e.name.toLowerCase()).includes(image.color.toLowerCase()))
              ? part.colors.map((e) => e.name).join(',') || part.partId
              : image.partId,
          // urlSmall: image.urlSmall || image.url,
          thumb: image.urlSmall || image.url,
          src: image.urlSmall || image.url,
          urlSmall: image.urlSmall || image.url,
        }));
      })
  );
  return {
    partsId: [...new Set(partsId)],
    media,
  };
}
export function getProductImages(productParts, imageDefault, withoutLogo = true, options) {
  let data = mediaList({ excludeType: ['Document', 'Video', 'Audio'], productParts, imageDefault });
  let resImgs = data.media,
    partsId = data.partsId;
  const setPartsIds = [...new Set([...resImgs.map((i) => i.partId || i.part_id), ...partsId])];
  let imagesWithoutPartUrls = [];
  let imagesWithoutPart = [];
  let uniqueImgs = [];
  let uniqueImgUrls = [];
  for (let i = 0; i < resImgs.length; i++) {
    if ((resImgs[i].partId || resImgs[i].part_id) && !setPartsIds.includes(resImgs[i].partId || resImgs[i].part_id)) {
      if (imagesWithoutPartUrls.indexOf(resImgs[i].url) < 0) {
        imagesWithoutPartUrls.push(resImgs[i].url);
        imagesWithoutPart.push(resImgs[i]);
      }
    } else {
      if (uniqueImgUrls.indexOf(resImgs[i].url) < 0) {
        uniqueImgUrls.push(resImgs[i].url);
        uniqueImgs.push(resImgs[i]);
      }
    }
  }
  resImgs = uniqueImgs;
  let tempImgs = [];
  let cloneImgs = [...resImgs.filter((a) => a.partId || a.part_id), ...resImgs.filter((a) => !(a.partId || a.part_id))];
  resImgs = [];
  cloneImgs.forEach((el) => {
    if (tempImgs.indexOf(el.urlSmall) < 0) {
      tempImgs.push(el.urlSmall);
      resImgs.push(el);
    }
  });

  let imagesWithPartIds = imageOrderPriority(
    resImgs.filter((a) => a.partId || a.part_id || a.productPart),
    [],
    options
  ).list;
  let imagesWithPartIdsID = imagesWithPartIds.map((el) => el.urlSmall);
  let defPartImages = imageOrderPriority(
    resImgs.filter((el) => el.defaultPart && imagesWithPartIdsID.indexOf(el.urlSmall) < 0),
    [],
    options
  ).list;

  imagesWithPartIdsID = [...imagesWithPartIdsID, ...defPartImages.map((el) => el.urlSmall)];

  const s = imageOrderPriority(resImgs, imagesWithPartIdsID, options).list;
  return [...imagesWithPartIds, ...defPartImages, ...s, ...imagesWithoutPart].filter(
    (i) => i && (!withoutLogo || !mediaHasClassTypeName(i, 'Logo'))
  );
}

export function findPartDefaultImageByColor(part, parts) {
  const getPartColorName = (p) =>
    p.colorName || p.colorNameLabel || p.partDescription || ((p.colors || [])[0] && p.colors[0].name);
  const color = getPartColorName(part);
  if (color) {
    const partWithDefaultImage = parts.find((p) => p.imageDefault && color === getPartColorName(p));
    return partWithDefaultImage && partWithDefaultImage.imageDefault;
  }
}

export function sortByMediaTypeNames(images, typesNames) {
  const priorityImages = (typesNames || []).reduce((list, mediaTypeName) => {
    const otherMediaTypes = (typesNames || []).filter(type => type !== mediaTypeName);
    otherMediaTypes.forEach(otherMediaType => {
      (images || []).forEach((i) => {
        if (!list.includes(i) && mediaHasClassTypeName(i, otherMediaType) && mediaHasClassTypeName(i, mediaTypeName)) {
          list.push(i);
        }
      });
    });
    (images || []).forEach((i) => {
      if (!list.includes(i) && mediaHasClassTypeName(i, mediaTypeName)) {
        list.push(i);
      }
    });
    return list;
  }, []);

  const resultImages = [...priorityImages, ...(images || []).filter((i) => !priorityImages.includes(i))];
  return resultImages;
}
